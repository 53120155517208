import Bugsnag from "@bugsnag/js"
import React from "react"
import C from "../config"
import { updateDb } from "../db/db"
import {publish} from "hooks/useSubscription"
//import { Query } from "../query"
//import { updateStyle } from "../sw/initSW"
//import { publish } from "../hooks/useSubscription"

export const updateStyle = style => {
    //console.log("update style", style)
    const links = document.getElementsByTagName("link")
    for (let i in links) {
        const link = links[i]
        if (
            !link ||
            !link.getAttribute ||
            link.getAttribute("rel") !== "stylesheet" ||
            link.href.indexOf(C.HOST) < 0
        )
            continue

        if (link.href.indexOf("css/site") >= 0 && style) link.href = style
    }
}

//const reCSS = /\.css$/

let ws
let tok
let cbs = {}
const process = (ws, data) => {
    try {
        const command = JSON.parse(data.data)
        //console.log("WS", command)
        if (command.re) {
            if (cbs[command.re]) cbs[command.re](command)
            return
        }
        switch (command.cmd) {
            //case "style-path":
            //      updatePageStyle(command.stylePath)
            //    break
            case "update": {
                if (command.type === "data") {
                    //console.log("WS CALL REFRESH")
                    //if (window.location.hostname === "localhost") Query.refresh()
                    updateDb()
                    const sw = navigator?.serviceWorker?.controller
                    if (sw)
                        sw.postMessage({
                            type: "APP_UPDATED",
                            meta: "client-window",
                            payload: window.location.pathname,
                        })
                }
                if (command.type === "site") {
                    publish("success", "Aplicatia a fost actualizata. Va rugam reincarcati site-ul")
                }
                if (command.type === "style") {
                    updateStyle(`${C.PROTO}://${C.HOST}${command.style}`)
                    /*setTimeout(() => {
                        publish("STYLE_UPDATE", command.style)
                    }, 1000)*/
                }
                //else {
                //}
                break
            }
            case "noop": {
                //console.log("noop")
                /*const { cmd, ...state } = command
                //console.log(window.appVersion, state)
                if (!window.appVersion) window.appVersion = state
                else {
                    if (window.appVersion.v !== state.v) {
                        if (window.appVersion.data !== state.data) {
                            //console.log("refresh data")
                            Query.refresh()
                        }
                        if (window.appVersion.style !== state.style) {
                            if (state.stylePath) updatePageStyle(state.stylePath)
                            else ws.send(JSON.stringify({ cmd: "get-style-path" }))

                            //console.log("refresh style")
                        }
                        if (window.appVersion.app !== state.app) {
                            //console.log("refresh app")
                        }
                        window.appVersion = state
                    }
                    //console.log("noop", cmd)
                }*/
                break
            }
            default:
                console.log("Unknown command", command)
        }
    } catch (e) {
        console.log(e)
    }
}
let initialDelay = 500
let delay = initialDelay
const connect = () => {
    if (ws) return
    //console.log("connect ws")

    let args
    if (tok) {
        args = ["token", tok]
    }
    try {
        //console.log("WS connect")
        ws = new WebSocket(`wss://${C.HOST}/wss`, args)
    } catch (e) {
        Bugsnag.notify(e)
        return
    }
    if (!ws) {
        setTimeout(() => {
            connect()
        }, delay)
        delay = delay * 2
        return
    }
    ws.onopen = () => {
        delay = initialDelay
        //console.log("onopen")
        try {
            if (ws) ws.send(JSON.stringify({ cmd: "noop" }))
        } catch (e) {
            console.log(e)
        }
    }

    ws.onmessage = data => {
        process(ws, data)
    }
    ws.onclose = () => {
        //console.log(e)
        ws = null
        //console.log("close ws")
        setTimeout(() => {
            connect(tok)
        }, initialDelay)
    }
    ws.onerror = () => {
        //console.log(e)
        ws = null
        //console.log("error ws")
        setTimeout(() => {
            connect(tok)
        }, delay)
        delay = delay * 2
    }
}
const disconnect = () => {
    if (ws) {
        ws.close()
        ws = null
    }
}
export const authWS = token => {
    tok = token
    disconnect()
    //connect()
}
export const logoutWS = () => {
    tok = null
    disconnect()
    //connect()
}

export const useWS = () => {
    React.useEffect(() => {
        //if (typeof window === "undefined") return
        connect()
    }, [])
}
export const sendCmd = async (cmd, data) => {
    if (!ws) return null
    return new Promise(resolve => {
        cbs[cmd] = data => {
            delete cbs[cmd]
            resolve(data)
        }
        ws.send(JSON.stringify({ cmd, data }))
    })
}
