import React from "react"
import { History } from "../../history"
import { useSubscriptionProvider } from "../../hooks/useSubscription"

const Link = ({
    domRef,
    context, _path, fieldPath, fieldEntity,
    fieldInfo,
    to,
    nocb,
    children,
    onActivate,
    message,
    ...props
}) => {
    const localRef = React.useRef()
    const ref = domRef ?? localRef
    const [anExternalLink, link] = React.useMemo(() => {
        if (!to || typeof to !== "string") return [true, to]
        const anExternalLink = !(to.startsWith("/") || to.startsWith("#"))
        if (anExternalLink) return [anExternalLink, to]
        let link = to.endsWith("/")
            ? `${to}index.html`
            : to.startsWith("#") || /\.[a-z0-9]{1,4}/.test(to)
            ? to
            : `${to}.html`
        return [anExternalLink, link]
    }, [to])

    const sendMessage = useSubscriptionProvider()
    const handleClick = React.useCallback(
        e => {
            //console.log("CLICK")
            const aNewTab = e.metaKey || e.ctrlKey
            if (!link) return

            const m = message ?? fieldInfo?.message
            if (m) sendMessage(m)

            if (!aNewTab && !anExternalLink) {
                if (onActivate) {
                    e.preventDefault()
                    //if (onActivate(e, link)) {
                    onActivate(e, link)
                    History.push(link, { nocb })
                    //}
                } else {
                    e.preventDefault()
                    History.push(link, { nocb })
                }
            }
        },
        [fieldInfo, message, nocb, onActivate, sendMessage, anExternalLink, link]
    )

    return (
        <a ref={domRef ?? ref} href={link} onClick={handleClick} {...props}>
            {children}
        </a>
    )
}

//export default React.forwardRef(Link)
export default Link
