import React from "react"
import { useAttrs } from "./hooks/useAttrs.js"
const Number = ({ context, state, dispatch, content, tag, isContent, ...attr }) => {
    const ref = React.useRef()
    const attrs = useAttrs(attr)
    const s = (content ?? context?.e ?? 0) / 100
    //console.log(info, content, s, attr)
    if (isContent) return s
    const Tag = tag ?? "div"
    return (
        <Tag ref={ref} elem-number="" {...attrs}>
            {s}
        </Tag>
    )
}
export default Number
