const converters = {
    price: (p, t) => {
        //console.log("CONVERT", p, t)
        if (typeof p?.amount !== "number" || isNaN(p?.amount))
            return {
                ...p,
                amount: 0,
            }
        if (t?.keys?.amount?.decimals > 2)
            return {
                ...p,
                amount: Math.round(p.amount / 10 ** (t?.keys?.amount?.decimals - 2)),
                _e: {
                    ...(p._e ?? {}),
                    amount: {
                        ...(p._e?.amount ?? {}),
                        decimals: 2,
                    },
                },
            }

        return p
    },
}
export default converters
