import React from "react"
//import { Entity } from "../entity"
import Link from "./widgets/Link"

const LinkRenderer = ({ context, _path, fieldPath, fieldEntity, config, link, _index, ...args }) => {
    //console.log(link, args)
    return (
        <Link to={link.path} {...args}>
            {link.label}
        </Link>
    )
    /*
    const { linkActivate } = state ?? {}
    const handleActivate = React.useCallback(() => {
        linkActivate()
        return true
    }, [linkActivate])
    const { _e, ...rest } = args
    console.log("LinkRenderer", args)
    const props = state?.linkActivate ? { ...rest, onActivate: handleActivate } : rest
    //const isLink = Entity.typeIs(info.typeInfo, "link") && !rest.to
    const isLink = !rest.to
    const typeProps = isLink ? { to: context.value?.path } : {}
    const label = isLink ? context.value?.label : ""
    //console.log(children, info, path, nodePath)
    return (
        <Link {...props} {...typeProps}>
            {label}
            {content}
            {typeof children === "function" ? children(context, state, dispatch) : children}
        </Link>
    )*/
}

export default LinkRenderer
//            {children?.(info.context, state, dispatch)}
