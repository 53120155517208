import React from "react"
import { History } from "../history"
import { LanguageContext } from "./LanguageContext"
import C from "../config"

export const LanguageManager = ({ children }) => {
    const [language, changeLanguage] = React.useState(History.getLocation()?.language)
    //console.log("LANGUAGE MANAGER RENDER")
    React.useEffect(() => {
        if (!C.LANGUAGES) return
        History.addLocationListener((pathname, search, hash, language) => {
            requestIdleCallback(
                () => {
                    //console.log("IDLE CB change lang")
                    changeLanguage(language)
                },
                { timeout: 1000 }
            )
        })
    }, [])
    const state = React.useMemo(
        () => ({
            language,
            changeLanguage,
        }),
        [language]
    )
    return <LanguageContext.Provider value={state}>{children}</LanguageContext.Provider>
}
