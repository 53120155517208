export const $lineage = Symbol("lineage")
export const $union = Symbol("union")
export const $keyOrder = Symbol("keyOrder")

//export const $config = Symbol("config")
export const $type = Symbol("type")
export const $keyType = Symbol("keyType")
export const $settingType = Symbol("settingType")
export const $calcField = Symbol("calcField")
//export const $parent = Symbol("parent")
