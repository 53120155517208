import React from "react"
import { Transition, TransitionGroup } from "react-transition-group"

const ItemTransition = ({ domRef, context, _path, fieldPath, fieldEntity, state, dispatch, children, ...args }) => {
    const {
        _e,
        wrapper,
        wrapperProps,
        itemKey,
        className,
        item,
        timeout,
        transitionProps,
        ...props
    } = args
    //const key = itemKey //? itemKey(item) : item
    const [itemClass, classes] = React.useMemo(() => {
        const c = className ?? "item"
        const toks = c.split(" ")
        return [toks[0], toks.slice(1).join(" ")]
    }, [className])
    const Item = item ?? "div"
    const t = timeout ?? 1000

    //console.log(props, itemKey, Item, itemClass, children)
    //if (typeof item === "object") return null
    return (
        <TransitionGroup
            appear={true}
            enter={true}
            component={wrapper ?? null}
            {...(wrapperProps ?? {})}
        >
            {itemKey !== "none" && (
                <Transition key={itemKey} unmountOnExit={true} timeout={t} {...transitionProps}>
                    {status => (
                        <Item
                            className={`${itemClass} ${itemClass}-${status} ${classes}`}
                            {...props}
                        >
                            {typeof children === "function"
                                ? children(context, state, dispatch)
                                : children}
                        </Item>
                    )}
                </Transition>
            )}
        </TransitionGroup>
    )
}
export default ItemTransition
//                            {withStatus ? children(status) : children}
