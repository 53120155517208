import React from "react"
export const useUnmounted = () => {
    const unmounted = React.useRef(false)
    React.useEffect(
        () => () => {
            unmounted.current = true
        },
        []
    )
    return unmounted
}
