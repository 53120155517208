const create = o => ({ ...o, _pub: true })

const save = () => {}
const load = () => {}
const remove = () => {}

export default {
    _inc: ["create"],
    _cont: "create",
    create,
    save,
    load,
    remove,
}
