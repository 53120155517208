import React from "react"

const Slider = ({ domRef, context, _path, fieldPath, fieldEntity, config, state, dispatch, children, ...args }) => {
    //console.log(context, args)
    //return children(context)
    const goto = React.useRef()
    const [active, setActive] = React.useState(0)
    const [items, timeout, newIndex, isEntity] = React.useMemo(() => {
        const { items, timeout, active, ...attrs } = args
        const isEntity = items?.[0]?.type && items?.[0]?._id
        return [items ?? [], timeout ?? 10000, active, isEntity, attrs]
    }, [args])
    React.useEffect(() => {
        if (goto.current !== newIndex) {
            goto.current = newIndex
            setActive(newIndex)
        }
        const t = setInterval(() => {
            setActive(active => (active < items.length - 1 ? active + 1 : 0))
        }, timeout)
        return () => {
            clearInterval(t)
        }
    }, [items, timeout, newIndex])
    const newContext = React.useMemo(
        () => ({
            ...context,
            activeSlide: items?.[active],
            active,
            ...(isEntity && items?.[active]
                ? { _path: [...context._path, { type: "entity", entity: items?.[active] }] }
                : {}),
        }),
        [active, items, context]
    )
    return children(newContext, items?.[active])
}

export default Slider
