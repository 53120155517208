import { getType, renameKeys, getSettings } from "../type"
import { compose } from "../schema"
//import apply from "../apply"
//import { getPath } from "../data"
import { $keyOrder, $keyType } from "../symbols"

const set = (parent, parentType, key, type, value) => {
    //console.log("MAP SET", parent, key, typeof key, value)
    if (!parentType.keys?.[key]) {
        if (parentType._closed) return null
        const settings = getSettings(parentType)
        if (settings.includes(key)) return null
    }
    return { ...parent, [key]: value }
}

const unset = (parent, parentType, key) => {
    //console.log("MAP REMOVEKEY", key, parent, parentType)
    if (!parent) return parent
    return [
        Object.keys(parent)
            .filter(k => k !== key)
            .reduce(
                (acc, k) =>
                    k === "_e"
                        ? {
                              ...acc,
                              _e: Object.keys(parent._e)
                                  .filter(k => k !== key)
                                  .reduce((a, k) => ({ ...a, [k]: parent._e[k] }), {}),
                          }
                        : k === "_o"
                        ? {
                              ...acc,
                              _o: parent._o
                                  .split(",")
                                  .filter(k => k !== key)
                                  .join(","),
                          }
                        : { ...acc, [k]: parent[k] },
                {}
            ),
        null,
    ]
}

const buildPath = (parent, parentType, key, path) => {
    //console.log("MAP BUILDPATH", key, parent, parentType, parent?.[$keyType])
    let type = parent?.[$keyType]?.[key]
    if (typeof type === "undefined") {
        type = keyType(parent, parentType, key)
        //console.log("MAPB", parent, parentType, key, type)
        if (!type) return null
        if (parent && typeof parent === "object") {
            if (!parent?.[$keyType] && Object.isExtensible(parent)) {
                Object.defineProperty(parent, $keyType, { value: {} })
            }
            if (parent[$keyType]) parent[$keyType][key] = type
        }
    }
    if (!type) return null
    const value = parent?.[key]
    //console.log("MAP", key, type)
    return type ? [...path, { parent, parentType, key, type, value }] : null
}

/*
obj - type def keys
block - _e
map - obj keys, -_forbidden
*/
const typeKeys = (o, type) => {
    //console.log("MAP TYPEKEYS", o, type)
    /*
      key types:
      - static
      - dynamic - _e
      - volatile => MapEditor
    */
    let t = type
    let staticKeys = Object.keys(type.keys ?? {}).reduce(
        (acc, key) => ({
            ...acc,
            [key]: { ...type.keys[key], keyType: "static" },
        }),
        {}
    )

    let configKeys = o?._e ? renameKeys(o._e) : {}
    //const hasattrs = o?._e?.attrs
    //if (hasattrs) console.log(o._e, configKeys)
    configKeys = Object.keys(configKeys).reduce((acc, key) => {
        if (staticKeys[key]) return { ...acc, [key]: configKeys[key] }
        if (type._closed) return acc
        const keyType = /^f\d+$/.test(key) ? "dynamic" : "volatile"
        if (typeof configKeys[key] === "string")
            return { ...acc, [key]: { is: configKeys[key], keyType } }
        if (!configKeys[key].is) {
            return {
                ...acc,
                [key]: { ...configKeys[key], is: type.values ?? "union", keyType },
            }
        }
        return { ...acc, [key]: { ...configKeys[key], keyType } }
    }, {})
    //if (hasattrs) console.log(configKeys, staticKeys)
    const volatileKeys = type._closed
        ? {}
        : Object.keys(o ?? {})
              .filter(
                  key =>
                      !staticKeys[key] && !configKeys[key] && !(type._forbidden ?? []).includes(key)
              )
              .reduce(
                  (acc, key) => ({
                      ...acc,
                      [key]: { is: type.values ?? "union", keyType: "volatile" },
                  }),
                  {}
              )
    //if (hasattrs) console.log(volatileKeys, staticKeys)
    t = { ...t, keys: compose(staticKeys, compose(configKeys, volatileKeys)) }

    //if (hasattrs) console.log(t)
    const ret = { ...t, [$keyOrder]: getKeyOrder(o, t) }
    //if (hasattrs) console.log(ret)
    //console.log("MAP TYPEKEYS RESULT", JSON.stringify(ret, null, "\t"))
    return ret
}

const keyType = (parent, parentType, key) => {
    //if (key === "attrs") console.log("MAP KEYTYPE", key, parent, parentType)
    if (!parentType.keys?.[key]) {
        if (parentType._closed) return null
        const settings = getSettings(parentType)
        if (settings.includes(key)) return null
        let config = { is: "t" }
        if (parentType.values)
            config = compose(
                config,
                typeof parentType.values === "string"
                    ? { is: parentType.values }
                    : parentType.values
            )
        if (parent?._e?.[key]) config = compose(config, parent._e[key])
        //return getType(parent?.[key], parentType.values)
        return getType(parent?.[key], config)
        //return null
    }
    return getType(parent?.[key], parentType.keys?.[key])
    //return apply(parent[fieldName], type, "typeKeys")
    //return buildChildType(key, parent, parentType, fieldName, e, initialPath)
}
const getKeyOrder = (o, t) => {
    const keyOrder = Object.keys(t.keys ?? {})
    if (!o?._o) return keyOrder

    if (typeof o._o === "string") {
        let order = (o._o && o._o.trim() !== "" ? o._o.split(",") : []).filter(f =>
            keyOrder.includes(f)
        )
        if (keyOrder.length > 0) return [...new Set(order.concat(keyOrder))]
        return order
    } else {
        const ret = {}
        for (const key of Object.keys(o._o)) {
            const currentFields = keyOrder
            ret[key] = o._o[key].split(",").filter(f => currentFields.includes(f))
            //fields = currentFields.filter(f => !ret[key].includes(f))
        }
        if (keyOrder.length > 0) {
            if (!ret["content"]) ret["content"] = keyOrder
            else ret["content"] = ret["content"].concat(keyOrder)
        }
        return ret
    }
}
export default {
    keyType,
    typeKeys,
    buildPath,
    set,
    unset,
}
