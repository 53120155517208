import { getType, typeFromTypeDef } from "../type"
import { compose } from "../schema"
//import { buildChildType } from "./map"
//import apply from "../apply"
import { $keyOrder, $keyType } from "../symbols"

const set = (parent, parentType, key, type, value) => {
    //console.log("MAP SET", parent, key, typeof key, value)
    if (!parentType.keys?.[key]) return null
    return { ...parent, [key]: value }
}

const buildPath = (parent, parentType, key, path) => {
    //console.log("REF BUILDPATH", key, parent, parentType)
    let type = parent?.[$keyType]?.[key]
    if (typeof type === "undefined") {
        type = keyType(parent, parentType, key)
        if (!type) return [...path, { parent, parentType, key, type, value: null }]
        if (parent && typeof parent === "object") {
            if (!parent?.[$keyType] && Object.isExtensible(parent)) {
                Object.defineProperty(parent, $keyType, { value: {} })
            }
            if (parent[$keyType]) parent[$keyType][key] = type
        }
    }
    if (!type) {
        return [...path, { parent, parentType, key, type, value: null }]
    }
    const value = parent?.[key]
    return [...path, { parent, parentType, key, type, value }]
}

const keyType = (parent, parentType, key) => {
    //console.log("REF KEYTYPE", fieldName, parent, parentType)

    if (key === "path") return typeFromTypeDef("path")
    if (key === "ref") return typeFromTypeDef("refid")
    const isCached =
        parentType.cache
            ?.split(",")
            ?.map(s => s.trim())
            ?.filter(f => f === key)?.length === 1
    if (!isCached) return null

    const refType = typeFromTypeDef(parentType.ref)
    if (!refType) return null
    const refKey = refType.keys?.[key]
    if (!refKey) return null
    return getType(parent?.[key], refKey)
    //return apply(parent[fieldName], type, "typeKeys")
}

const typeKeys = (o, type) => {
    //console.log("REF TYPEKEYS", o, type)
    let keys = { ref: "refid" }
    let keyNames = type.cache?.split(",")?.map(s => s.trim()) ?? []
    if (keyNames.length > 0) {
        const refType = typeFromTypeDef(type.ref)
        if (!refType) return null
        keys = keyNames.reduce(
            (acc, key) =>
                key === "path"
                    ? {
                          ...acc,
                          [key]: "path",
                      }
                    : refType.keys?.[key]
                    ? {
                          ...acc,
                          [key]: refType.keys[key],
                      }
                    : acc,
            { ref: "refid" }
        )
    }
    //console.log("REF TYPEKEYS2", keys)
    const ret = compose(type, { keys })
    //console.log("REF TYPEKEYS3", ret)
    return { ...ret, [$keyOrder]: Object.keys(keys) }
}

export default {
    keyType,
    typeKeys,
    buildPath,
    set,
}
