import { Query } from "../query"

export const load = async (_id, collection = "node") => {
    const options = {
        collection,
        query: { _id },
    }

    const results = await Query.select(options)
    //console.log(results)
    if (!results || !results.results || results.total < 1) return null
    return results.results[0]
}

export const loadById = (_id, collection) => {
    const options = {
        collection,
        query: { _id },
    }

    const results = Query.execute(options)
    //console.log("RESULTS", results)
    if (results instanceof Promise) {
        return new Promise((resolve, reject) =>
            results
                .then(res => resolve(res?.results?.[0]))
                .catch(e => {
                    reject(e)
                })
        )
    }
    return results?.results?.[0]
}
