import React from "react"
import C from "../config"
import { LanguageManager } from "../lang"
import { History, useHistory } from "../history"
import { useSubscription } from "../hooks/useSubscription"
import { updateDb } from "../db/db"
import Location from "./Location"
import Auth from "../auth/Auth"
import { HelmetProvider } from "react-helmet-async"
//import { elements } from "../tpl"
//const { Site } = elements
import { useLanguageContext } from "lang"
import { Site } from "../tpl"

const AppInner = () => {
    const [user, setUser] = React.useState()
    const { language } = useLanguageContext()
    const [isShell, setIsShell] = React.useState(History.getLocation().pathname === "/shell")
    useHistory(location => {
        setIsShell(location.pathname === "/shell")
    })
    //console.log("RENDER SITE")
    const handleSub = React.useCallback((topic, arg) => {
        //if (url === location.pathname) return
        //console.log("SITE openurl", url)
        switch (topic) {
            case "openURL":
                History.push(arg)
                return
            default:
                setUser(arg)
        }
    }, [])
    useSubscription(["openURL", "user"], handleSub)
    const [context, _path] = React.useMemo(() => {
        //console.log("SITE NEW INFO")
        return [{
            user,
            language,
            //_path: [],
        }, []]
    }, [user, language])
    React.useEffect(() => {
        if (isShell) return
        setTimeout(() => {
            requestIdleCallback(() => {
                navigator?.serviceWorker?.controller?.postMessage({
                    type: "SITE-LOADED",
                    payload: window.location.pathname,
                })
                if (C.isLocal) updateDb()
            })
        }, 5000)
    }, [isShell])
    //console.log("APP ISSHELL", isShell)
    if (isShell) return <Auth />
    return (
        <>
            <Site context={context} _path={_path}/>
            <Location />
            <Auth />
        </>
    )
}
const App = () => (
    <HelmetProvider>
        <LanguageManager>
            <AppInner />
        </LanguageManager>
    </HelmetProvider>
)

export default App
