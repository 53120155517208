export const capitalize = str => str[0].toUpperCase() + str.slice(1)
export const uncapitalize = str => str[0].toLowerCase() + str.slice(1)
export const makeName = name =>
    name
        .split(/[^a-zA-Z0-9]/)
        .map(capitalize)
        .join("")

export const nameAttr = (type, name) =>
    type +
    "-" +
    name
        .split(/([A-Z])/)
        .filter(t => !!t)
        .map((t, i) => (i > 0 && /[A-Z]/.test(t) ? "-" + t.toLowerCase() : t.toLowerCase()))
        .join("")
