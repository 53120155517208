import React from "react"
import FaIcon from "./widgets/FaIcon"
//import { useCarousel } from "./Carousel"
/*
const isDisabled = (carousel, direction) => {
    if (carousel.state.type === "single") return undefined
    const visible = carousel.state.visible ?? 1
    if (visible === 1) return undefined
    if (carousel.items?.length <= visible) return ""
    if (direction === -1) {
        if (carousel.state.current === 0) return ""
        return undefined
    }

    if (carousel.state.current < carousel.items?.length - visible) return undefined
    return ""
}*/
//data-disabled={isDisabled(carousel, -1)}
//data-disabled={isDisabled(carousel, 1)}
const Arrows = ({ carousel }) => {
    //const carousel = useCarousel()

    if (carousel.n <= carousel.nv) return null
    return (
        <div arrows="">
            <div arrow="left" onClick={carousel.prev}>
                <FaIcon icon="filled/arrow_right_alt" />
            </div>

            <div arrow="right" onClick={carousel.next}>
                <FaIcon icon="filled/arrow_right_alt" />
            </div>
        </div>
    )
}
export default Arrows
