import React from "react"
const dict = {
    inputType: "type",
}
const permitted = ["id", "className", "style", "inputType"]
const attrFilter = attr => permitted.includes(attr) || attr.indexOf("-") > 0

export const useAttrs = props =>
    React.useMemo(
        () =>
            Object.keys(props)
                .filter(attrFilter)
                .reduce((acc, key) => {
                    const k = dict[key] ?? key
                    acc[k] = props[key]
                    return acc
                }, {}),
        [props]
    )
