//import { $lineage, $keyOrder } from "./symbols"
/*
const composeKeys = (baseType, type) => {
    if (!type.keys && !baseType.keys) return {}
    const baseKeys = Object.keys(baseType.keys ?? {})
    const typeKeys = Object.keys(type.keys ?? {})
    //console.log("COMPOSE KEYS", baseKeys, typeKeys)
    const keys = baseKeys.reduce(
        (acc, key) => ({
            ...acc,
            [key]: compose(baseType.keys[key], type.keys?.[key]),
        }),
        typeKeys
            .filter(key => !baseKeys.includes(key))
            .reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: type.keys[key],
                }),
                {}
            )
    )
    return {
        keys,
        [$keyOrder]: Object.keys(keys),
    }
}

const composeSettings = (baseType, type) => {
    if (!type.settings && !baseType.settings) return {}
    const baseKeys = Object.keys(baseType.settings ?? {})
    const typeKeys = Object.keys(type.settings ?? {})
    const settings = baseKeys.reduce(
        (acc, key) => ({
            ...acc,
            [key]: compose(baseType.settings[key], type.settings?.[key]),
        }),
        typeKeys
            .filter(key => !baseKeys.includes(key))
            .reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: type.settings[key],
                }),
                {}
            )
    )
    return {
        settings,
    }
}

const composeClasses = (baseType, type) => {
    const baseClasses = baseType.classes ?? []
    return {
        classes: [...(type.classes ?? []).filter(c => !baseClasses.includes(c)), ...baseClasses],
    }
}
const composeItems = (baseType, type) => {
    if (!baseType?.items && !type?.items) return {}
    if (baseType?.items && type?.items) {
        if (typeof baseType.items === "string" && typeof type.items === "string")
            return { items: type.items }
        return {
            items: {
                ...(typeof baseType.items === "string" ? { is: baseType.items } : baseType.items),
                ...(typeof type.items === "string" ? { is: type.items } : type.items),
            },
        }
    }
    return { items: type?.items ?? baseType?.items }
}
/*
const compose = (baseType, type) => {
    if (!baseType) return type
    if (!type) return baseType
    //console.log("COMPOSE", type, baseType)

    //const lineage = composeLineage(type, baseType)
    const keys = composeKeys(baseType, type)
    const settings = composeSettings(baseType, type)
    const classes = composeClasses(baseType, type)
    const items = composeItems(baseType, type)

    const { is, ...rest } = type
    const ret = {
        ...baseType,
        ...(is === baseType.name ? rest : type),
        ...items,
        ...keys,
        ...classes,
        ...settings,
    }
    //console.log("COMPOSE RESULT", ret)
    return ret
    }*/

const overrideArrays = ["keys", "default"]
const comp = (t1, t2, key) => {
    //console.log("COMP", t1, t2)
    if (!t1 || t2 === null) return t2
    if (Array.isArray(t2)) {
        if (Array.isArray(t1)) {
            if (!overrideArrays.includes(key))
                return [...t2.filter(item => !t1.includes(item)), ...t1]
            //return [...t2.filter(item => !(t1 ?? [])?.includes(item)), ...(t1 ?? [])]
        }
        return t2
    }
    if (typeof t2 === "object" && typeof t1 === "object")
        return Object.keys(t2).reduce(
            (acc, key) => {
                acc[key] = comp(t1?.[key], t2[key], key)
                return acc
            },
            Object.getOwnPropertySymbols(t2).reduce(
                (acc, s) => {
                    acc[s] = comp(t1?.[s], t2[s], s)
                    return acc
                },
                { ...(t1 ?? {}) }
            )
        )

    /*
      Object.getOwnPropertySymbols(t2).reduce(
      (acc, s) => {
      acc[s] = comp(t1?.[s], t2[s], s)
      return acc
      },
      { ...(t1 ?? {}) }
      )
*/
    if (typeof t2 === "object" && typeof t1 === "string") {
        if (t1 === "union") return comp({ is: "union" }, t2)
        console.log("COMPOSE STR_OBJ", t1, t2)
    }
    return t2
}

const compose = (baseType, type) => {
    if (!baseType) return type
    if (!type) return baseType
    //console.log("COMPOSE", baseType, type)
    //console.log("COMPOSE", baseType, type)
    const bType = typeof baseType === "string" ? { is: baseType } : baseType
    const t = typeof type === "string" ? { is: type } : type

    //const { is, ...rest } = t
    //const ret = comp(bType, is === bType.name ? rest : t, null)
    const ret = comp(bType, t, null)
    //console.log("COMPOSE", baseType, type, ret)
    return ret
}
export { compose }
