import { useState, useCallback, useEffect } from "react"
import { History } from "./history"
import { useUnmounted } from "../hooks/useUnmounted"

const pushSearch = params => {
    if (!params || Object.keys(params).length === 0) History.push(window.location.pathname)
    else {
        const search = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join("&")
        History.push(`${window.location.pathname}?${search}`)
    }
}
export const useHistory = cb => {
    const unmounted = useUnmounted()
    const [location, setLocation] = useState(History.getLocation())

    const handleLocationChange = useCallback(
        (pathname, search, hash, language, sanitized) => {
            requestIdleCallback(
                () => {
                    //console.log("IDLE CB - useHistory")
                    if (unmounted.current) return
                    if (cb) cb({ pathname, search, language, sanitized })
                    else setLocation({ pathname, search, language, sanitized })
                },
                { timeout: 1000 }
            )
        },
        [unmounted, cb]
    )
    useEffect(() => {
        History.addLocationListener(handleLocationChange)

        return () => History.removeLocationListener(handleLocationChange)
    }, [handleLocationChange])
    return {
        location,
        push: History.push,
        pushSearch,
    }
}
