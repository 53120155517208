import C from "../config"
import axios from "axios"
//const C={}
let _id = 0
let cbs = {}
export const triggerUpdates = updates => {
    const ts = Math.floor(Date.now())
    Object.values(cbs).forEach(cb => cb(ts, updates))
}
export const registerForUpdates = cb => {
    _id++
    cbs[_id] = cb
    return _id
}
export const unregisterForUpdates = id => {
    delete cbs[id]
}
export const updateDb = async () => {
    //console.log("update db")
    const instance = axios.create({ baseURL: C.BASE })
    try {
        const data = await instance.get("/db.json")
        window._db = data.data
        triggerUpdates()
    } catch (e) {
        console.log(e)
    }
}
export const mergeUpdates = res => {
    console.log(res)
    const { data } = res.data
    window._db = { ...window._db, ...data }
    triggerUpdates()
}
