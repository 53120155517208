import React from "react"
import { getElements, getElementTypes } from "./register"
import { uncapitalize, nameAttr } from "./util/util"
import ImageList from "./ImageList"
import String from "./String"
import Number from "./Number"
import HTML from "./HTML"
import Link from "./Link"
//import { useAdmin } from "./hooks/useAdmin.js"

const renderers = {
    img: ImageList,
    string: String,
    text: String,
    html: HTML,
    number: Number,
    link: Link,
}
export const hashstr = s => {
    let hash = 0
    if (s.length === 0) return hash
    for (let i = 0; i < s.length; i++) {
        let char = s.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return ("" + hash).replace("-", "_")
}

const Field = props => {
    const { context, config, value, type, content, parent, field, ...rest } = props
    const language = context.language
    const { fieldPath } = rest
    //_path,
    //fieldPath,
    //fieldEntity,
    //console.log("FIELD", props)
    //useAdmin(context)
    const [Tag, attrs] = React.useMemo(() => {
        const v = value //i18n?.[language]?.[field] ?? value
        const elements = getElements()
        let attrs = {}
        let f = field
        if (!f && fieldPath) f = fieldPath.split(".").slice(-1)[0]
        if (f) attrs[nameAttr("field", f)] = ""
        if (type) attrs[nameAttr("type", type)] = ""

        if (config?.id) attrs.id = config.id
        if (config?._class) attrs.className = config._class
        if (config?._layout) {
            const name = `FieldLayout_${hashstr(JSON.stringify(config._layout))}`
            if (elements[name]) {
                if (type) attrs[type] = v
                //console.log(name, props)
                return [elements[name], attrs]
            }
        }
        if (type) {
            if (type === "elemWidget") {
                const elementName = v?.element?.name
                const Tag = elements?.[elementName]
                if (!Tag) return [null, null]
                const pseudo = uncapitalize(elementName)
                attrs[pseudo] = v.attrs

                return [Tag, attrs]
            }
            if (type === "layoutWidget") {
                const name = `Layout_${hashstr(JSON.stringify(v?.layout))}`
                if (elements[name]) {
                    if (type) attrs[type] = v
                    //console.log(name, props)
                    return [elements[name], attrs]
                }
            }
            const elementTypes = getElementTypes()
            const elType = elementTypes?.[type]
            //console.log(type, elType, renderers)
            attrs[type] = v
            if (renderers[type]) return [renderers[type], attrs]
            if (elType) return [elType, attrs]
        }
        if (parent) {
            let p = parent
            if (parent?._e?.is === "elemWidget") {
                //let type = parent?.value?.attrs
                p = parent?.value?.attrs
            }
            let type = p?._e?.[field]?._type?.is ?? p?._e?.[field]?._type ?? p?._e?.[field]?.is
            if (type === "exprValExt") type = "string"
            if (!type) {
                if (p?.[field]?.$$typeof) {
                    return [null, p?.[field]]
                }
                if (typeof p?.[field] === "string") type = "string"
                else {
                    console.log("FIELDCONFIG", field, parent, parent?._e?.[field])
                    return [null, null]
                }
            }
            //console.log(field, type)
            //if (!type) console.log(parent)
            const elementTypes = getElementTypes()
            const elType = elementTypes?.[type]
            attrs = {
                ...attrs,
                [nameAttr("field", field)]: "",
                [nameAttr("type", type)]: "",
                [type]: p[field],
            }
            //console.log(type, attrs)
            if (renderers[type]) return [renderers[type], attrs]
            if (elType) return [elType, attrs]
        }
        //console.log(props)
        return [null, null]
    }, [type, value, language, parent, field, fieldPath, props])

    if (!Tag) {
        if (attrs) return attrs
        console.log("FIELD NOTAG", props)
        return <div>NOTAG: field={props.field}</div>
    }
    return <Tag context={context} config={config} {...attrs} {...rest} />
}

export default Field
